<template>
  <default-layout>
    <div class="borrower_exigency mt-7 px-7 text-center">
      <img src="@/assets/images/inputmoney.svg" class="inline" alt />
      <div class="mt-3 borrower_exigency_form">
        <div class="text-left">
          <label class="input-label font-bold">Số tiền bạn muốn ứng</label>
          <base-input
            v-model:modelValue="amount"
            typeInput="text"
            suffix="VNĐ"
            placeholder="Nhập số tiền"
            mode="numeric"
            length="15"
            class="text-primary"
            :spacing="!!amount"
            :bold="!!amount"
            :clearable="!!amount"
            @change-data="changeData"
            @focus="handleFocus"
          />
          <div v-if="!errorMoney" class="text-xs">
            <span>{{ messeages }}</span>
          </div>
          <base-text-error :errorMessages="errorMoney" />
        </div>
        <div class="text-left mt-1">
          <label class="input-label font-bold"
            >Bạn muốn ứng trong bao lâu</label
          >
          <base-input
            v-model:modelValue="term"
            suffix="Tháng"
            typeInput="text"
            mode="numeric"
            placeholder="Nhập kì hạn ứng"
            @focus="handleFocus"
          />
          <base-text-error :errorMessages="errorTerm" />
        </div>
        <div class="text-left mt-1">
          <label class="input-label font-bold">Lương hàng tháng của bạn</label>
          <base-input
            v-model:modelValue="monthlyIncome"
            typeInput="text"
            suffix="VNĐ"
            placeholder="Nhập số tiền"
            mode="numeric"
            :clearable="!!monthlyIncome"
            @change-data="changeDataIncome"
            @focus="handleFocus"
          />
          <base-text-error :errorMessages="errorSalary" />
        </div>
        <div class="text-left mt-1">
          <label class="input-label font-bold">Ngày nhận lương tiếp theo</label>
          <base-date-picker
            v-model:modelValue="dateSalary"
            placeholder="Chọn ngày nhận lương tiếp theo"
            @focus="handleFocus"
          />
          <base-text-error :errorMessages="errorDate" />
        </div>
        <div v-if="paidMoney" class="bg-primary rounded-xl text-white mt-5 p-3">
          <div class="flex justify-between items-start mt-1">
            <p>Phí xử lý giao dịch: <br /></p>
            <p class="font-bold">{{ paidMoney }} VNĐ</p>
          </div>
          <div class="flex justify-between items-start mt-1">
            <p>Số tiền thực nhận:</p>
            <p class="font-bold">{{ realMoney }} VNĐ</p>
          </div>
          <div class="flex justify-between items-start mt-1">
            <p class="text-left">
              Tiền trả hàng tháng: <br />
              <i>(dự kiến)</i>
            </p>
            <p class="font-bold whitespace-nowrap">{{ monthlyPayment }} VNĐ</p>
          </div>
        </div>
        <base-button
          @click="handleSubmit"
          class="w-full border-16 bg-tertiary text-white my-5 py-6"
          >Tiếp tục
        </base-button>
      </div>
    </div>
  </default-layout>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex';
import { Soros } from '@/helpers/numberToWords';
import {
  numberToMoney,
  isNumeric,
  divisible100,
  moneyToNumber,
} from '../../utils/index';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import constRouter from '@/constants/constRouter';
import { createSalary } from '@/apis/ApiCreateNeeded';
import { createLoan } from '@/apis/ApiBorrower';
import { required } from '@/helpers/validate';
import { INTEREST } from '@/constants/index';
import { getAuthToken } from '../../helpers/localStorage';
import BaseDatePicker from '@/components/Base/BaseDatePicker.vue';
import { computeMonthlyPayment, computeTransFee } from '../../utils/computeFee';
import { getCurrentLongDate } from '@/helpers/helperTime';

const format = new Soros('vi');
export default {
  name: 'BorrowerExigency',
  components: {
    BaseInput,
    BaseButton,
    BaseTextError,
    DefaultLayout,
    BaseDatePicker,
  },
  data() {
    return {
      amount: '',
      dateSalary: '',
      date: '',
      term: '',
      monthlyIncome: '',
      errorMoney: '',
      errorDate: '',
      errorTerm: '',
      errorSalary: '',
      messeages: '',
      paidMoney: '',
      realMoney: '',
      monthlyPayment: '',
      isSalary: false,
    };
  },
  computed: {
    ...mapGetters({
      getExigency: 'getExigency',
      getUser: 'getUser',
      campaign: 'getCampaign',
    }),
    showFooter() {
      return !!getAuthToken();
    },
    calculateMonthlyPayment() {
      const { amount, term } = this;
      return {
        amount,
        term,
      };
    },
  },
  created() {
    if (this.$route.name === constRouter.BORROWER_MONEY.name) {
      this.isSalary = false;
    }
    if (this.$route.name === constRouter.BORROWER_SALARY.name) {
      this.isSalary = true;
    }
    this.setSalary(this.isSalary);
    this.amount = numberToMoney(this.getExigency.amount);
    this.term = this.getExigency.term;
    this.monthlyIncome = this.getUser.monthlyIncome
      ? numberToMoney(parseInt(this.getUser.monthlyIncome))
      : numberToMoney(this.getExigency.monthlyIncome);
    this.date = this.getUser.salaryDate
      ? this.getUser.salaryDate
      : this.getExigency.dateSalary;
    this.dateSalary = this.date && getCurrentLongDate(this.date.toString());
  },
  watch: {
    calculateMonthlyPayment(value) {
      if (isNumeric(moneyToNumber(value.amount)) && isNumeric(value.term)) {
        const money = moneyToNumber(value.amount);

        const paid = computeTransFee(parseInt(value.term), money);
        this.monthlyPayment = numberToMoney(
          computeMonthlyPayment(parseInt(value.term), money, INTEREST),
        );

        this.paidMoney = numberToMoney(paid);
        this.realMoney = numberToMoney(Math.max(money - paid, 0));
      } else {
        this.paidMoney = '';
        this.realMoney = '';
        this.monthlyPayment = '';
      }
    },
    amount(value) {
      if (value) {
        value = value.replace(/\./g, '');
        if (!isNumeric(value)) {
          this.messeages = '';
          this.paidMoney = '';
          return;
        }
        //render so tien theo chu
        let mes = format.run(value);
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);
        this.messeages = mes.replace('0.', '') + ' đồng';
        this.amount = numberToMoney(parseInt(value));
      } else {
        this.messeages = '';
        this.amount = '';
        this.paidMoney = '';
      }
    },
    monthlyIncome(value) {
      if (value) {
        value = value.replace(/\./g, '');
        if (!isNumeric(value)) {
          return;
        }
        this.monthlyIncome = numberToMoney(parseInt(value));
      }
    },
    dateSalary(value) {
      if (value) {
        this.date = parseInt(value.split('-')[2]);
      }
    },
  },
  methods: {
    ...mapMutations({
      setExigency: 'setExigency',
      setSalary: 'setSalary',
      setContractId: 'setContractId',
    }),
    changeData(val) {
      this.amount = val;
    },
    changeDataIncome(val) {
      this.monthlyIncome = val;
    },
    handleFocus() {
      this.errorMoney = this.errorDate = this.errorTerm = this.errorSalary = '';
    },
    validate() {
      let result = true;
      this.errorMoney = required('Amount', this.amount);
      this.errorDate = required('DateSalary', this.date);
      this.errorTerm = required('Term', this.term);
      this.errorSalary = required('Salary', this.monthlyIncome);
      if (!this.amount || !this.date || !this.term || !this.monthlyIncome) {
        return false;
      }
      if (!isNumeric(moneyToNumber(this.amount))) {
        this.errorMoney = 'Số tiền không hợp lệ';
        result = false;
      } else {
        if (!divisible100(moneyToNumber(this.amount))) {
          this.errorMoney = 'Số tiền phải chia hết cho 100.000';
          result = false;
        }
      }
      if (!isNumeric(this.term)) {
        this.errorTerm = 'Kì hạn ứng phải là số';
        result = false;
      }
      if (!isNumeric(moneyToNumber(this.monthlyIncome))) {
        this.errorSalary = 'Lương hàng tháng phải là số';
        result = false;
      }

      let number = moneyToNumber(this.amount);
      if (this.amount && isNumeric(number)) {
        number = +number;
        if (number < 500000) {
          this.errorMoney = 'Số tiền tối thiểu là 500.000 VNĐ';
          result = false;
        }
      }
      return result;
    },
    handleSubmit() {
      if (this.validate()) {
        this.setExigency({
          amount: moneyToNumber(this.amount),
          paidMoney: moneyToNumber(this.paidMoney),
          realMoney: moneyToNumber(this.realMoney),
          monthlyPayment: moneyToNumber(this.monthlyPayment),
          dateSalary: this.date,
          term: this.term,
          monthlyIncome: moneyToNumber(this.monthlyIncome),
        });
        //check da dang nhap chua
        //da dang nhap => thi tao khoan ung
        if (localStorage.getItem('accessToken')) {
          //ung luong
          if (this.isSalary) {
            createSalary({
              amount: moneyToNumber(this.amount),
            }).then(res => {
              this.setContractId(res.data?.data?.id);
            });
            this.$router.push({
              name: constRouter.REGISTER_SUCCESS.name,
            });
          }
          //ung tien
          else {
            const {
              utmSource,
              utmCampaign,
              utmContent,
              clickId,
            } = this.campaign;

            createLoan({
              amount: moneyToNumber(this.amount),
              term: this.term,
              utmSource,
              utmCampaign,
              utmContent,
              clickId,
            })
              .then(res => {
                // if (res.data?.data?.isSupported) {
                //   this.$router.push({
                //     name: constRouter.REGISTER_SUCCESS.name,
                //   });
                // } else {
                //   this.$router.push({
                //     name: constRouter.BORROWER_CHOOSE_LOAN.name,
                //   });
                // }
                this.$router.push({
                  name: constRouter.REGISTER_SUCCESS.name,
                });
                this.setContractId(res.data?.data?.id);
              })
              .catch(err => {
                console.log(err);
                this.errorMoney = err.data?.message;
              });
          }
        }
        // chua dang nhap => thì sang trang đăng kí điện thoại
        else {
          this.$router.push({
            name: constRouter.REGISTER_PHONE.name,
          });
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.borrower_exigency {
  .borrower_exigency_form {
    .fee {
      margin-right: 10px;
      text-decoration: line-through;
    }
  }
}
</style>
